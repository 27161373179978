<template>
  <section id="usuario-nuevo" v-if="$store.state.appConfig.rol == 3">
    <b-col cols="14">
      <b-card>
        <div>
          <!-- Header: Personal Info -->
          <div class="d-flex">
            <h4 class="my-1">
              {{
                this.$route.query.userId
                  ? 'Editar usuario'
                  : 'Crear nuevo Usuario'
              }}
            </h4>
          </div>
          <!-- <div id="loading-bg" v-if="loading">
            <div class="loading">
              <div class="effect-1 effects"></div>
              <div class="effect-2 effects"></div>
              <div class="effect-3 effects"></div>
            </div>
          </div> -->

          <!-- Form: Personal Info Form -->
          <b-form class="mt-1">
            <b-row>
              <!-- Field: Nombre -->
              <b-col cols="12" md="6" lg="6">
                <b-form-group label="1. Nombre *" label-for="nombre">
                  <b-form-input
                    id="nombre"
                    v-model="userDataInfo.nombre"
                    :state="aceptadoNombre"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Apellido -->
              <b-col cols="12" md="6" lg="6">
                <b-form-group label="2. Apellido *" label-for="apellido">
                  <b-form-input
                    id="apellido"
                    v-model="userDataInfo.apellido"
                    :state="aceptadoApellido"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Email -->
              <b-col cols="12" md="6" lg="6">
                <b-form-group label="3. Email *" label-for="email">
                  <b-form-input
                    id="email"
                    v-model="userDataInfo.email"
                    :state="aceptadoEmail"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Productos -->
              <b-col cols="12" md="6" lg="6">
                <h2
                  class="admin-message"
                  v-if="this.userDataInfo.rol == 'ADMIN'"
                >
                  Se agregan todos los productos
                </h2>
                <b-form-group
                  label="4. Productos *"
                  label-for="productos"
                  :state="aceptadoProductos"
                  v-if="this.userDataInfo.rol != 'ADMIN'"
                >
                  <v-select
                    id="selectProductos"
                    v-model="userDataInfo.productos"
                    :dir="ltr"
                    multiple
                    label="title"
                    @input="ver()"
                    :options="option"
                    :state="aceptadoProductos"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Rol -->
              <b-col cols="12" md="6" lg="8">
                <b-form-group
                  label="5. Rol *"
                  label-for="rol"
                  label-class="mb-1"
                >
                  <b-row>
                    <b-form-radio-group
                      id="rol"
                      v-model="userDataInfo.rol"
                      :options="rolOptions"
                      @change="cambiaRol(userDataInfo.rol)"
                      value="value"
                      class="custom-control-info pl-2"
                    />

                    <div v-if="!aceptadoRol">
                      <feather-icon
                        icon="AlertCircleIcon"
                        size="16"
                        class="m-0 p-0"
                        style="color:red"
                      />
                    </div>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>

          <!-- botones -->
          <div class="row align-items-center mt-5">
            <div>
              <b-alert
                v-height-fade.appear
                :show="dismissCountDown"
                dismissible
                class="mb-0 ml-1"
                variant="warning"
                id="empty-field-popup"
                @dismissed="dismissCountDown = 0"
                @dismiss-count-down="countDownChanged"
              >
                <div class="alert-body">
                  <span class="bold">Advertencia! </span>
                  <span>Debes completar los campos obligatorios </span>
                </div>
              </b-alert>
            </div>
            <div class="col justify-content-end">
              <div class="text-md-right text-center">

                  <!-- <div>
                    <b-alert
                      v-height-fade.appear
                      :show="dismissCountDown"
                      dismissible
                      class="mb-0 ml-1"
                      variant="warning"
                      id="empty-field-popup"
                      @dismissed="dismissCountDown = 0"
                      @dismiss-count-down="countDownChanged"
                    >
                      <div class="alert-body">
                        <span class="bold">Advertencia! </span>
                        <span>Debes completar los campos obligatorios </span>
                      </div>
                    </b-alert>
                  </div> -->
                  <b-button
                    id="cancel-button"
                    variant="outline-primary"
                    class="mr-1  mb-md-0 mb-1"
                    @click="modalCancelarShow = !modalCancelarShow"
                    v-b-modal.modal-cancelar
                  >
                    Cancelar
                  </b-button>
                  <b-button
                    v-if="this.$route.query.userId"
                    variant="primary"
                    class="shadow-none mr-1  mb-sm-0 mb-1"
                    @click="validateEmptyField"
                  >
                    Editar usuario
                  </b-button>
                  <b-button
                    v-if="!this.$route.query.userId"
                    variant="primary"
                    class="shadow-none mb-sm-0 mb-1"
                    @click="validateEmptyField"
                  >
                    Agregar usuario
                  </b-button>
                </div>
            </div>
          </div>
        </div>
      </b-card>
    </b-col>

    <b-modal
      id="modal-cancelar"
      body-bg-variant="light-warning"
      v-model="modalCancelarShow"
      hide-footer
      hide-header
      centered
      size="sm"
      ref="modal-cancelar"
    >
      <label id="label-confirmacion-cancelar">
        ¿ Seguro que quieres cancelar?
      </label>
      <b-row>
        <b-col>
          <b-button
            variant="warning"
            class="mr-1 modal-button-size shadow-none"
            @click="hideCancelarModal"
          >
            No
          </b-button>
          <router-link to="/dashboard/usuarios">
            <b-button
              variant="outline-warning"
              class="modal-button-size shadow-none"
            >
              Sí
            </b-button>
          </router-link>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BCard,
  BModal,
  BAlert,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import axios from 'axios';
import { backendUrl } from '@/config';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  data() {
    return {
      dir: 'ltr',
      selected: [1, 2, 3, 4],
      option: [],
      allProduct: [],
      productEdit: [],
      dismissSecs: 5,
      dismissCountDown: 0,
      aceptadoNombre: null,
      aceptadoApellido: null,
      aceptadoEmail: null,
      aceptadoProductos: null,
      aceptadoRol: true,
      modalCancelarShow: false,
    };
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BCard,
    BModal,
    BAlert,
    ToastificationContent,
  },

  mounted() {
    if (this.$store.state.appConfig.rol != 3) {
      this.$router.push({ path: '/' });
    } else {
      this.checkQueryParam();
      this.getProductos();
    }
  },

  setup() {
    const userDataInfo = ref({
      nombre: '',
      apellido: '',
      email: '',
      rol: '',
      pass: '',
      productos: [],
    });

    const rolOptions = [
      { text: 'Administrador', value: 'ADMIN' },
      { text: 'Basico', value: 'BASIC' },
      { text: 'Sólo lectura', value: 'READ' },
    ];

    return {
      userDataInfo,
      rolOptions,
    };
  },
  methods: {
    showUsuarioErrorToast(variant){
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Hubo un problema al crear el usuario',
          text:'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    showUsuarioCreadoExitosoToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Se ha creado un Usuario exitosamente!',
          icon: 'CheckIcon',
          variant,
        },
      });
    },
    async ver() {
      this.proyectosSeleccionados = this.userDataInfo.productos;
    },

    showUsuarioModificadoExitosoToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Se ha modificado el Usuario exitosamente!',
          icon: 'CheckIcon',
          variant,
        },
      });
    },
    showUsuarioModificadoErrorToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error al Modificar el Usuario',
          text: 'Intente nuevamente',
          icon: 'CrossIcon',
          variant,
        },
      });
    },
    showUsuarioCreadoErrorToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Ya existe un usuario con ese Email',
          text: 'Por favor, intente con otro',
          icon: 'CrossIcon',
          variant,
        },
      });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.aceptadoRol = false;
      this.dismissCountDown = this.dismissSecs;
    },
    hideCancelarModal() {
      this.$refs['modal-cancelar'].hide();
    },
    async getProductos() {
      const token = localStorage.getItem('token');

      const products = await axios({
        method: 'get',
        url: `${backendUrl}/productos/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const productDb = products.data.project;

      this.allProduct = productDb;

      for (let i = 0; i < productDb.length; i++) {
        this.option.push(productDb[i].name);
      }
    },
    checkQueryParam() {
      const { userId, name, email, rol, project } = this.$route.query;

      if (userId) {
        this.userDataInfo.nombre = name.split(' ')[0];
        this.userDataInfo.apellido = name.split(' ')[1];
        this.userDataInfo.email = email;
        this.userDataInfo.rol = rol;
        this.userDataInfo.productos = project.split(',');
      }
    },
    cambiaRol(rol) {
      this.userDataInfo.rol = rol;

      if (this.userDataInfo.rol != 'ADMIN') {
        this.userDataInfo.productos = this.proyectosSeleccionados;
      } else {
        if (this.userDataInfo.productos) {
          this.userDataInfo.productos = [];
        }
        this.userDataInfo.productos = this.option;

       
      }
    },

    validateEmptyField() {
     


      if (
        this.userDataInfo.nombre != '' &&
        this.userDataInfo.apellido != '' &&
        this.userDataInfo.email != '' &&
        this.userDataInfo.rol != '' &&
       ( this.userDataInfo.productos != null  && this.userDataInfo.productos != "" && this.userDataInfo.productos != undefined)
      ) {
        this.aceptadoRol = true;
        if (this.$route.query.userId) {
          this.handleEdit();
        } else {
          this.handleAdd();
        }
      } else {
        this.showAlert();
        this.userDataInfo.nombre == ''
          ? (this.aceptadoNombre = false)
          : (this.aceptadoNombre = true);
        this.userDataInfo.apellido == ''
          ? (this.aceptadoApellido = false)
          : (this.aceptadoApellido = true);
        this.userDataInfo.email == ''
          ? (this.aceptadoEmail = false)
          : (this.aceptadoEmail = true);
        this.userDataInfo.rol == ''
          ? (this.aceptadoRol = false)
          : (this.aceptadoRol = true);
        this.userDataInfo.productos == null || this.userDataInfo.productos == undefined
          ? (this.aceptadoProductos = false)
          : (this.aceptadoProductos = true);
      }
    },

    async handleAdd() {
      const token = localStorage.getItem('token');
      this.$store.commit("appConfig/changeLoading",true);
      let idProduct = [];

     

      for (let i = 0; i < this.userDataInfo.productos.length; i++) {
        const dbProduct = await this.allProduct.filter(
          (e) => e.name == this.userDataInfo.productos[i]
        );
        
        idProduct.push(dbProduct[0].id);
      }

      var data = JSON.stringify({
        email: this.userDataInfo.email,
        pass: this.userDataInfo.pass,
        timezone: 'BSAS',
        name: `${this.userDataInfo.nombre} ${this.userDataInfo.apellido}`,
        role: this.userDataInfo.rol,
        projects: idProduct,
      });

      var config = {
        method: 'post',
        url: `${backendUrl}/usuarios/add/`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      };
      try {
        const resp = await axios(config);
        if(resp.data.code == 1){
          this.$store.commit("appConfig/changeLoading",false);
          this.userDataInfo.email = ""

          this.showUsuarioCreadoErrorToast('danger');
          return
        }
        const users = await axios({
          method: 'get',
          url: `${backendUrl}/usuarios/`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        this.$store.commit('appConfig/saveUsuarios', users.data.user);
       
        this.$store.commit("appConfig/changeLoading",false);
        this.showUsuarioCreadoExitosoToast('success');
        this.$router.push('/dashboard/usuarios');
      } catch (error) {
     
        this.$store.commit("appConfig/changeLoading",false);
        this.showUsuarioErrorToast('danger');
      }
    },
    async handleEdit() {
      const token = localStorage.getItem('token');
      this.$store.commit("appConfig/changeLoading",true);
      let idProduct = [];
      let producList = [];

      for (let i = 0; i < this.userDataInfo.productos.length; i++) {
        const dbProduct = this.allProduct.filter(
          (e) => e.name === this.userDataInfo.productos[i]
        );
        idProduct.push(dbProduct[0].id);

        producList.push({
          id: dbProduct[0].id,
          name: dbProduct[0].name,
        });
      }
      var data = JSON.stringify({
        id: this.$route.query.userId,
        email: this.userDataInfo.email,
        timezone: 'BSAS',
        name: `${this.userDataInfo.nombre} ${this.userDataInfo.apellido}`,
        role: this.userDataInfo.rol,
        projects: idProduct,
      });

      var config = {
        method: 'post',
        url: `${backendUrl}/usuarios/edit/`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      };

      try {
        await axios(config);
        const users = await axios({
          method: 'get',
          url: `${backendUrl}/usuarios/`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.$store.commit('appConfig/saveUsuarios', users.data.user);
      
        this.$store.commit("appConfig/changeLoading",false);
        this.showUsuarioModificadoExitosoToast('success');
        this.$router.push('/dashboard/usuarios');
      } catch (error) {
     
        this.$store.commit("appConfig/changeLoading",false);
        this.showUsuarioModificadoErrorToast('danger');
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#selectProductos .vs__selected-options .vs__selected {
  background: $info;
  border-radius: 20px;
}
.hide {
  display: none;
}
.labelRolInvalida {
  color: red;
}

#cancel-button {
  width: 146.22px;
}
.admin-message {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

#label-confirmacion-cancelar {
  margin-left: 22%;
  margin-bottom: 16px;
  color: $warning !important;
  font-weight: bold;
  font-size: 14px;
}

.modal-button-size {
  width: 173px;
}
//SPINNER
#loading-logo {
  width: 50%;
}
#loading-bg {
  width: 100%;
  height: 100%;
  background: transparent;
  display: block;
  position: absolute;
}
.loading-logo {
  position: absolute;
  left: calc(46% - 45px);
  top: 40%;
}
.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}
.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.loading .effects {
  transition: all 0.3s ease;
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}
</style>
